@import "../../sass/colors.scss";

.section-title {
  margin-bottom: 50px;
  width: fit-content;
  span {
    color: $color-blue;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
    display: inline-block;
  }

  h2 {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    color: $color-black;
    font-weight: 500;
  }
}

// Mobile Responsive
@media (max-width: 575px) {
  .section-title {
    margin-bottom: 20px;

    span {
      margin-bottom: 5px;
    }

    h2 {
      font-size: 30px;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .section-title {
    margin-bottom: 20px;
    span {
      margin-bottom: 5px;
    }
    h2 {
      font-size: 30px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 30px;
    span {
      margin-bottom: 5px;
    }
    h2 {
      font-size: 30px;
    }
  }
}
