@import "./../../sass/fonts.scss";
@import "./../../sass/colors.scss";
.select_lang {
  display: none;
  width: fit-content;
  margin: 0 0 0 auto;
  @media (max-width: 990px) {
    display: block;
  }
}
.select_lang_show {
  display: block;
  @media (max-width: 990px) {
    display: none;
  }
}
.main-nav {
  padding: 15px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: $color-white;

  .navbar {
    padding: 0;

    .navbar-brand {
      img {
        max-width: 150px;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $color-black;
          font-size: 15px;
          font-weight: 600;
          padding: 0;
          margin: 15px;

          &:hover {
            color: $color-blue;
          }
        }
      }
    }
  }
}

// Mobile Responsive
@media (max-width: 575px) {
  .main-nav {
    padding: 10px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .navbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            margin: 10px 0px;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .main-nav {
    padding: 10px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .navbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            margin: 10px 0px;
          }
        }
      }
    }
  }
}

// Medium Devices Responsive
@media (min-width: 768px) and (max-width: 991px) {
  .main-nav {
    padding: 15px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .navbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            margin: 10px 0px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-nav {
    padding: 20px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .navbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            margin: 10px 15px;
          }
        }
      }
    }
  }
}
