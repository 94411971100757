@import "../../sass/colors.scss";

.single-testimoni {
  width: 100%;
  padding: 50px 10px 0;
  margin-bottom: 30px;
}

.testimonial-card {
  text-align: center;
  background: $color-white;
  border-radius: 32px;
  padding: 30px;
  min-height: 300px;
  position: relative;

  .testimonial-img {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto;

    img {
      border: 8px solid #d9ebfa;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .testimonial-text {
    h3 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    p {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }

  .testimonial-rating {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      color: #ff8c4b;
      font-size: 25px;
      margin: 0 2px;

      &:last-child {
        color: #c4c4c4;
      }
    }
  }
}
