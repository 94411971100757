.signin-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to bottom right,
    #4b6cb7,
    #182848
  ); /* Example gradient */
}

.signin-form {
  max-width: 300px;
  width: 100%;
  padding: 20px;
  background: #fff; /* Optional: Add a background color to the form */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
