.testimoni-slider {
  position: relative;
  .slick-arrow {
    display: none !important;
  }
}
.testimonial-slider-wrapper {
  position: relative;
  width: 100%;
}

.slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.slider-nav-left {
  left: 10px;
}

.slider-nav-right {
  right: 10px;
}
.container-div {
  width: 100%;
  @media (min-width: 640px) {
    max-width: 640px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    max-width: 1024px;
  }
  @media (min-width: 1280px) {
    max-width: 1280px;
  }
  @media (min-width: 1536px) {
    max-width: 1536px;
  }
}
